import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-interviewee-communications',
  templateUrl: './interviewee-communications.component.html',
  styleUrls: ['./interviewee-communications.component.scss']
})
export class IntervieweeCommunicationsComponent implements OnInit {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: string;
  public intervieweeCommunicationsForm: FormGroup;

  constructor(private fb: FormBuilder,
              private backendService: BackendService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.intervieweeCommunicationsForm = this.fb.group({
      postInterviewPackage: [null],
      dvd: [null],
      postInterviewCall: [null],
      postInterviewCallNotes: [null],
      deceased: [null]
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getIntervieweeCommunications(this.interviewID).subscribe(information => {
        if (information) {
          this.intervieweeCommunicationsForm = this.fb.group({
            postInterviewPackage: [ information.postInterviewPackage ],
            dvd: [ information.dvd ],
            postInterviewCall: [ information.postInterviewCall ],
            postInterviewCallNotes: [ information.postInterviewCallNotes ],
            deceased: [ information.deceased ]
          });
        }
      });
    }
  }

  saveForm() {
    const postInterviewPackage = this.intervieweeCommunicationsForm.controls['postInterviewPackage'].value;
    const dvd = this.intervieweeCommunicationsForm.controls['dvd'].value;
    const postInterviewCall = this.intervieweeCommunicationsForm.controls['postInterviewCall'].value;
    const postInterviewCallNotes = this.intervieweeCommunicationsForm.controls['postInterviewCallNotes'].value;
    const deceased = this.intervieweeCommunicationsForm.controls['deceased'].value;

    const interviewID = this.interviewID;
    let data = {
      interviewID,
      postInterviewPackage,
      dvd,
      postInterviewCall,
      postInterviewCallNotes,
      deceased
    };
    this.backendService.saveIntervieweeCommunications(data)
      .subscribe(() => this.toastr.success('Interviewee Communications', 'Data saved successfully'))
    this.formSaved.emit();
  }

  onPaste(event: ClipboardEvent, formControlName: string) {
  }

}
