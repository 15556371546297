import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-interview-review-courtesy',
  templateUrl: './interview-review-courtesy.component.html',
  styleUrls: ['./interview-review-courtesy.component.scss']
})
export class InterviewReviewCourtesyComponent implements OnInit {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: number;
  public courtesyForm: FormGroup;

  constructor(private fb: FormBuilder,
              private backendService: BackendService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.courtesyForm = this.fb.group({
      politeAndProfessional: [null ],
      respectful: [null ],
      interestedAndEngaged: [null ],
      interruption: [null ],
      clearly: [null ],
      sensitive: [null ],
      score: [null ],
      courtesyNotes: [null ],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getInterviewReviewCourtesy(this.interviewID).subscribe(information => {
        if (information) {
          this.courtesyForm = this.fb.group({
            politeAndProfessional: [information.politeAndProfessional ],
            respectful: [information.respectful ],
            interestedAndEngaged: [information.interestedAndEngaged ],
            interruption: [information.interruption ],
            clearly: [information.clearly ],
            sensitive: [information.sensitive ],
            score: [information.score ],
            courtesyNotes: [information.courtesyNotes ],
          });
        }
      });
    }
  }

  saveForm() {
    const politeAndProfessional = this.courtesyForm.controls['politeAndProfessional'].value;
    const respectful = this.courtesyForm.controls['respectful'].value;
    const interestedAndEngaged = this.courtesyForm.controls['interestedAndEngaged'].value;
    const interruption = this.courtesyForm.controls['interruption'].value;
    const clearly = this.courtesyForm.controls['clearly'].value;
    const sensitive = this.courtesyForm.controls['sensitive'].value;
    const score = this.courtesyForm.controls['score'].value;
    const courtesyNotes = this.courtesyForm.controls['courtesyNotes'].value;
    const interviewID = this.interviewID;
    let data = {
      interviewID, politeAndProfessional, respectful, interestedAndEngaged,
      interruption, clearly, sensitive, score, courtesyNotes
    };
    this.backendService.saveInterviewReviewCourtesy(data)
      .subscribe(() => this.toastr.success('Courtesy', 'Data saved successfully'))
    this.formSaved.emit();
  }

  onPaste(event: ClipboardEvent, formControlName: string) {
  }

}
