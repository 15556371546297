<div class="main-container">
    <div class="back-button-container" style="display:flex;justify-content:space-between;">
        <div>
            <button class="back-button d-flex align-items-center pl-3 pr-1" (click)="toDashboard()">
                <img src="../../../assets/images/arrow-left.png" class="mr-3">
                Back to Dashboard
            </button>
        </div>

        <div style="align-self:center">
            <b>Interview ID:</b> {{ interviewType }}{{ interviewID }}
            <b>Interviewee:</b> {{ intervieweeName === '' || !intervieweeName ? 'N/A' : intervieweeName }}
        </div>
    </div>
    <div class="submission-form-container d-flex">
        <div class="summary-container pl-4 pr-4 pt-3 pb-3" style="flex: 0 0 25em">
            <div class="summary-header d-flex">
                <div class="pointer" [ngClass]="{'selected-form': context === 'submission' }" (click)="changeContext('submission')">
                    Submission Form
                </div>
                <div class="ml-4 pointer"  *ngIf="userType !==  'interviewer'" [ngClass]="{'selected-form': context === 'archivist' }" (click)="changeContext('archivist')">
                    Archivist
                </div>
            </div>
            <div *ngIf="context === 'submission'">
                <div class="summary-title">
                    Interview Information
                </div>
                <div class="summary-item pointer pointer" [ngClass]="{'font-weight-bold': currentSubmissionStep === 1 }"
                    (click)="changeSubmissionStep(1)">
                    Interviewee Information
                </div>
                <div class="summary-item pointer pointer" [ngClass]="{'font-weight-bold': currentSubmissionStep === 2 }"
                    (click)="changeSubmissionStep(2)">
                    Interviewer Information
                </div>
                <div class="summary-item pointer pointer" [ngClass]="{'font-weight-bold': currentSubmissionStep === 3 }"
                    (click)="changeSubmissionStep(3)">
                    Narrator Information
                </div>
                <div class="summary-item pointer pointer" [ngClass]="{'font-weight-bold': currentSubmissionStep === 4 }"
                    (click)="changeSubmissionStep(4)">
                    Interview Date
                </div>
                <div class="summary-item pointer pointer" [ngClass]="{'font-weight-bold': currentSubmissionStep === 5 }"
                    (click)="changeSubmissionStep(5)">
                    Interview Location
                </div>
                <div class="summary-item pointer pointer" [ngClass]="{'font-weight-bold': currentSubmissionStep === 6 }"
                    (click)="changeSubmissionStep(6)">
                    Additional Interview Information
                </div>
                <div class="summary-item pointer pointer" [ngClass]="{'font-weight-bold': currentSubmissionStep === 7 }"
                    (click)="changeSubmissionStep(7)">
                    Interview Summary/Abstract
                </div>
                <div class="summary-title">
                    Migration Information
                </div>
                <div class="summary-item pointer pointer" [ngClass]="{'font-weight-bold': currentSubmissionStep === 8 }"
                    (click)="changeSubmissionStep(8)">
                    Birthplace Location
                </div>
                <div class="summary-item pointer pointer" [ngClass]="{'font-weight-bold': currentSubmissionStep === 9 }"
                    (click)="changeSubmissionStep(9)">
                    Interviewee Residence Before Partition
                </div>
                <div class="summary-item pointer pointer" [ngClass]="{'font-weight-bold': currentSubmissionStep === 10 }"
                    (click)="changeSubmissionStep(10)">
                    Interviewee Residence After Partition
                </div>
                <div class="summary-item pointer pointer" [ngClass]="{'font-weight-bold': currentSubmissionStep === 11 }"
                    (click)="changeSubmissionStep(11)">
                    Migration Details
                </div>
                <div class="summary-item pointer pointer" [ngClass]="{'font-weight-bold': currentSubmissionStep === 12 }"
                    (click)="changeSubmissionStep(12)">
                    Interviewee Occupation and Parental Information
                </div>
                <div class="summary-title">
                    Contact Information
                </div>
                <div class="summary-item pointer pointer" [ngClass]="{'font-weight-bold': currentSubmissionStep === 13 }"
                    (click)="changeSubmissionStep(13)">
                    Friends and Family Information
                </div>
                <div class="summary-item pointer pointer" [ngClass]="{'font-weight-bold': currentSubmissionStep === 14 }"
                    (click)="changeSubmissionStep(14)">
                    Interviewee Contact Information
                </div>
                <div class="summary-item pointer pointer" [ngClass]="{'font-weight-bold': currentSubmissionStep === 15 }"
                    (click)="changeSubmissionStep(15)">
                    Interviewer Contact Information
                </div>
                <div class="summary-item pointer pointer" [ngClass]="{'font-weight-bold': currentSubmissionStep === 16 }"
                    (click)="changeSubmissionStep(16)">
                    Narrator Contact Information
                </div>
                <div class="summary-title">
                    Interviewee Preferences
                </div>
                <div class="summary-item pointer" [ngClass]="{'font-weight-bold': currentSubmissionStep === 17 }"
                    (click)="changeSubmissionStep(17)">
                    Interviewee Preferences
                </div>
                <div class="summary-title">
                    Submission Files
                </div>
                <div class="summary-item pointer" [ngClass]="{'font-weight-bold': currentSubmissionStep === 18 }"
                    (click)="changeSubmissionStep(18)">
                    Interview Audio/Video Files
                </div>
                <div class="summary-item pointer" [ngClass]="{'font-weight-bold': currentSubmissionStep === 19 }"
                    (click)="changeSubmissionStep(19)">
                    Interview Photo Files
                </div>
                <div class="summary-item pointer" [ngClass]="{'font-weight-bold': currentSubmissionStep === 20 }"
                    (click)="changeSubmissionStep(20)">
                    Signed Release Form
                </div>
                <div class="summary-item pointer" [ngClass]="{'font-weight-bold': currentSubmissionStep === 21 }"
                    (click)="changeSubmissionStep(21)">
                    Other Files
                </div>
            </div>
            <div *ngIf="context === 'archivist'">
                <div class="summary-title">
                    Archiving Process
                </div>
                <div class="summary-item pointer" [ngClass]="{'font-weight-bold': currentArchivistStep === 1 }"
                    (click)="changeArchivistStep(1)">
                    Archivist
                </div>
                <div class="summary-item pointer" [ngClass]="{'font-weight-bold': currentArchivistStep === 2 }"
                    (click)="changeArchivistStep(2)">
                    Archivist Contact Information
                </div>
                <div class="summary-item pointer" [ngClass]="{'font-weight-bold': currentArchivistStep === 3 }"
                    (click)="changeArchivistStep(3)">
                    Transcription Information
                </div>

                <div class="summary-title">
                    Abstract Curation
                </div>
                <div class="summary-item pointer" [ngClass]="{'font-weight-bold': currentArchivistStep === 4 }"
                     (click)="changeArchivistStep(4)">
                  Copy-Editing and Curation
                </div>

                <div class="summary-title">
                    Interview Review
                </div>
                <div class="summary-item pointer" [ngClass]="{'font-weight-bold': currentArchivistStep === 5 }"
                     (click)="changeArchivistStep(5)">
                      Content
                </div>
                <div class="summary-item pointer" [ngClass]="{'font-weight-bold': currentArchivistStep === 6 }"
                     (click)="changeArchivistStep(6)">
                      Courtesy
                </div>
                <div class="summary-item pointer" [ngClass]="{'font-weight-bold': currentArchivistStep === 7 }"
                     (click)="changeArchivistStep(7)">
                      Filming
                </div>
                <div class="summary-item pointer" [ngClass]="{'font-weight-bold': currentArchivistStep === 8 }"
                     (click)="changeArchivistStep(8)">
                      Responses & Reaction
                </div>
                <div class="summary-item pointer" [ngClass]="{'font-weight-bold': currentArchivistStep === 9 }"
                     (click)="changeArchivistStep(9)">
                      Red Flags
                </div>
                <div class="summary-item pointer" [ngClass]="{'font-weight-bold': currentArchivistStep === 10 }"
                    (click)="changeArchivistStep(10)">
                    Interview File Format
                </div>

                <ng-container *ngIf="userType !== 'archivist'">
                    <div class="summary-title">
                        Post-Archiving Process
                    </div>
                    <div class="summary-item pointer" [ngClass]="{'font-weight-bold': currentArchivistStep === 11 }"
                        (click)="changeArchivistStep(11)">
                        Sponsor Information
                    </div>
                    <div class="summary-item pointer" [ngClass]="{'font-weight-bold': currentArchivistStep === 12 }"
                        (click)="changeArchivistStep(12)">
                        Social Media Curation
                    </div>
                    <div class="summary-item pointer" [ngClass]="{'font-weight-bold': currentArchivistStep === 13 }"
                        (click)="changeArchivistStep(13)">
                        StoryMap
                    </div>
                    <div class="summary-item pointer" [ngClass]="{'font-weight-bold': currentArchivistStep === 14 }"
                        (click)="changeArchivistStep(14)">
                        Interviewee Communications
                    </div>
                    <div *ngIf="userType !==  'archivist'" class="summary-item pointer" [ngClass]="{'font-weight-bold': currentArchivistStep === 15 }"
                        (click)="changeArchivistStep(15)">
                        Pseudonym
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="form-container" style="flex-grow: 4;">
            <ng-content></ng-content>
        </div>
    </div>
</div>
