import { Component, ElementRef, EventEmitter, Input, Output,SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';
@Component({
  selector: 'app-photo-files',
  templateUrl: './photo-files.component.html',
  styleUrls: ['./photo-files.component.scss'],
})
export class PhotoFilesComponent {
  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: string;

  @ViewChild('myInput')
  myInputVariable: ElementRef;

  public photoCaptionsForm: FormGroup;

  public fileType = 'photo';

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private backendService: BackendService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.photoCaptionsForm = this.fb.group({
      photo1Caption: [null],
      photo2Caption: [null],
      photo3Caption: [null],
      photo4Caption: [null],
      photo5Caption: [null],
      photo6Caption: [null],
      photo7Caption: [null],
      photo8Caption: [null],
      photo9Caption: [null],
      photo10Caption: [null],
      photo11Caption: [null],
      photo12Caption: [null],
      additionalCaptions: [null],
      olderPhotosAmount: [null],
      originalPhotoFormat: [null]
    });

    this.setValidators();
  }

  setValidators() {
    /*if (this.authService.getUserType() === 'interviewer') {
      this.photoCaptionsForm.controls['photosAmount'].setValidators(Validators.required);
      this.photoCaptionsForm.controls['photosAmount'].updateValueAndValidity();
      this.photoCaptionsForm.controls['photo1Caption'].setValidators(Validators.required);
      this.photoCaptionsForm.controls['photo1Caption'].updateValueAndValidity();
      this.photoCaptionsForm.controls['photo2Caption'].setValidators(Validators.required);
      this.photoCaptionsForm.controls['photo2Caption'].updateValueAndValidity();
      this.photoCaptionsForm.controls['photo3Caption'].setValidators(Validators.required);
      this.photoCaptionsForm.controls['photo3Caption'].updateValueAndValidity();
      this.photoCaptionsForm.controls['photo4Caption'].setValidators(Validators.required);
      this.photoCaptionsForm.controls['photo4Caption'].updateValueAndValidity();
      this.photoCaptionsForm.controls['photo5Caption'].setValidators(Validators.required);
      this.photoCaptionsForm.controls['photo5Caption'].updateValueAndValidity();
    }*/
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getPhotoCaptions(this.interviewID).subscribe(photoCaptions => {
        if (photoCaptions) {
          this.photoCaptionsForm= this.fb.group({
            photo1Caption: [ photoCaptions.photo1Caption ],
            photo2Caption: [ photoCaptions.photo2Caption ],
            photo3Caption: [ photoCaptions.photo3Caption ],
            photo4Caption: [ photoCaptions.photo4Caption ],
            photo5Caption: [ photoCaptions.photo5Caption ],
            photo6Caption: [ photoCaptions.photo6Caption ],
            photo7Caption: [ photoCaptions.photo7Caption ],
            photo8Caption: [ photoCaptions.photo8Caption ],
            photo9Caption: [ photoCaptions.photo9Caption ],
            photo10Caption: [ photoCaptions.photo10Caption ],
            photo11Caption: [ photoCaptions.photo11Caption ],
            photo12Caption: [ photoCaptions.photo12Caption ],
            additionalCaptions: [ photoCaptions.additionalCaptions ],
            olderPhotosAmount: [ photoCaptions.olderPhotosAmount ],
            originalPhotoFormat: [ photoCaptions.originalPhotoFormat ]
          });

          this.setValidators();
        }
      });
    }
  }

  get userType() {
    return this.authService.getUserType();
  }

  saveForm() {
      const photo1Caption = this.photoCaptionsForm.controls['photo1Caption'].value;
      const photo2Caption = this.photoCaptionsForm.controls['photo2Caption'].value;
      const photo3Caption = this.photoCaptionsForm.controls['photo3Caption'].value;
      const photo4Caption = this.photoCaptionsForm.controls['photo4Caption'].value;
      const photo5Caption = this.photoCaptionsForm.controls['photo5Caption'].value;
      const photo6Caption = this.photoCaptionsForm.controls['photo6Caption'].value;
      const photo7Caption = this.photoCaptionsForm.controls['photo7Caption'].value;
      const photo8Caption = this.photoCaptionsForm.controls['photo8Caption'].value;
      const photo9Caption = this.photoCaptionsForm.controls['photo9Caption'].value;
      const photo10Caption = this.photoCaptionsForm.controls['photo10Caption'].value;
      const photo11Caption = this.photoCaptionsForm.controls['photo11Caption'].value;
      const photo12Caption = this.photoCaptionsForm.controls['photo12Caption'].value;
      const additionalCaptions = this.photoCaptionsForm.controls['additionalCaptions'].value;
      const olderPhotosAmount = this.photoCaptionsForm.controls['olderPhotosAmount'].value;
      const originalPhotoFormat = this.photoCaptionsForm.controls['originalPhotoFormat'].value;
      const interviewID = this.interviewID;

      let data = {
        interviewID,
        photo1Caption,
        photo2Caption,
        photo3Caption,
        photo4Caption,
        photo5Caption,
        photo6Caption,
        photo7Caption,
        photo8Caption,
        photo9Caption,
        photo10Caption,
        photo11Caption,
        photo12Caption,
        additionalCaptions,
        olderPhotosAmount,
        originalPhotoFormat
      };
      this.backendService.savePhotoCaptions(data)
        .subscribe(() => this.toastr.success('Interview Photo Files', 'Data saved successfully'))
      this.formSaved.emit();
  }

  onPaste(event: ClipboardEvent, formControlName: string) {
  }
}
