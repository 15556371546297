import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-family-information',
  templateUrl: './family-information.component.html',
  styleUrls: ['./family-information.component.scss']
})
export class FamilyInformationComponent implements OnInit {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: string;
  public familyInformationForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private backendService: BackendService,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.familyInformationForm = this.fb.group({
      contact1FullName: [null],
      contact1FirstName: [null ],
      contact1LastName: [null ],
      contact1Relation: [null ],
      contact1Address: [null ],
      contact1HouseNumber: [null],
      contact1StreetName: [null],
      contact1City: [null ],
      contact1District: [null],
      contact1State: [null ],
      contact1PostalCode: [null],
      contact1Country: [null ],
      contact1ResidenceDetails: [null],
      contact1Phone: [null ],
      contact1Email: [null ],
      contact1Facebook: [null],
      contact1Twitter: [null],
      contact1Instagram: [null],
      contact1Linkedin: [null],
      contact1Tiktok: [null],
      contact2FullName: [null],
      contact2FirstName: [null ],
      contact2LastName: [null ],
      contact2Relation: [null ],
      contact2FullAddress: [null ],
      contact2HouseNumber: [null],
      contact2StreetName: [null],
      contact2City: [null ],
      contact2District: [null],
      contact2State: [null ],
      contact2PostalCode: [null],
      contact2Country: [null ],
      contact2ResidenceDetails: [null],
      contact2Phone: [null ],
      contact2Email: [null ],
      contact2Facebook: [null],
      contact2Twitter: [null],
      contact2Instagram: [null],
      contact2Linkedin: [null],
      contact2Tiktok: [null],
      contact3FullName: [null],
      contact3FirstName: [null],
      contact3LastName: [null],
      contact3Relation: [null],
      contact3FullAddress: [null],
      contact3HouseNumber: [null],
      contact3StreetName: [null],
      contact3City: [null],
      contact3District: [null],
      contact3State: [null],
      contact3PostalCode: [null],
      contact3Country: [null],
      contact3ResidenceDetails: [null],
      contact3Phone: [null],
      contact3Email: [null],
      contact3Facebook: [null],
      contact3Twitter: [null],
      contact3Instagram: [null],
      contact3Linkedin: [null],
      contact3Tiktok: [null],
      contact4FullName: [null],
      contact4FirstName: [null],
      contact4LastName: [null],
      contact4Relation: [null],
      contact4FullAddress: [null],
      contact4HouseNumber: [null],
      contact4StreetName: [null],
      contact4City: [null],
      contact4District: [null],
      contact4State: [null],
      contact4PostalCode: [null],
      contact4Country: [null],
      contact4ResidenceDetails: [null],
      contact4Phone: [null],
      contact4Email: [null],
      contact4Facebook: [null],
      contact4Twitter: [null],
      contact4Instagram: [null],
      contact4Linkedin: [null],
      contact4TikTok: [null],
      contact5FullName: [null],
      contact5FirstName: [null],
      contact5LastName: [null],
      contact5FullAddress: [null],
      contact5Relation: [null],
      contact5HouseNumber: [null],
      contact5StreetName: [null],
      contact5City: [null],
      contact5District: [null],
      contact5State: [null],
      contact5PostalCode: [null],
      contact5Country: [null],
      contact5ResidenceDetails: [null],
      contact5Phone: [null],
      contact5Email: [null],
      contact5Facebook: [null],
      contact5Twitter: [null],
      contact5Instagram: [null],
      contact5Linkedin: [null],
      contact5Tiktok: [null],
      contact6FullName: [null],
      nextOfKin6: [null],
      kin6Phone: [null],
      kin6Email: [null],
      contact7FullName: [null],
      nextOfKin7: [null],
      kin7Phone: [null],
      kin7Email: [null],
      contact8FullName: [null],
      nextOfKin8: [null],
      kin8Phone: [null],
      kin8Email: [null],
    });

    this.setValidators();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getFamilyInformation(this.interviewID).subscribe(information => {
        if (information) {
          this.familyInformationForm = this.fb.group({
            contact1FullName: [information.contact1FullName],
            contact1FirstName: [information.contact1FirstName ],
            contact1LastName: [information.contact1LastName ],
            contact1Relation: [information.contact1Relation ],
            contact1Address: [information.contact1Address ],
            contact1HouseNumber: [information.contact1HouseNumber],
            contact1StreetName: [information.contact1StreetName],
            contact1City: [information.contact1City ],
            contact1District: [information.contact1District],
            contact1State: [information.contact1State ],
            contact1PostalCode: [information.contact1PostalCode],
            contact1Country: [information.contact1Country ],
            contact1ResidenceDetails: [information.contact1ResidenceDetails],
            contact1Phone: [information.contact1Phone ],
            contact1Email: [information.contact1Email ],
            contact1Facebook: [information.contact1Facebook],
            contact1Twitter: [information.contact1Twitter],
            contact1Instagram: [information.contact1Instagram],
            contact1Linkedin: [information.contact1Linkedin],
            contact1Tiktok: [information.contact1Tiktok],
            contact2FullName: [information.contact2FullName],
            contact2FirstName: [information.contact2FirstName ],
            contact2LastName: [information.contact2LastName ],
            contact2Relation: [information.contact2Relation ],
            contact2FullAddress: [information.contact2FullAddress],
            contact2HouseNumber: [information.contact2HouseNumber],
            contact2StreetName: [information.contact2StreetName],
            contact2City: [information.contact2City ],
            contact2District: [information.contact2District],
            contact2State: [information.contact2State ],
            contact2PostalCode: [information.contact2PostalCode],
            contact2Country: [information.contact2Country ],
            contact2ResidenceDetails: [information.contact2ResidenceDetails],
            contact2Phone: [information.contact2Phone ],
            contact2Email: [information.contact2Email ],
            contact2Facebook: [information.contact2Facebook],
            contact2Twitter: [information.contact2Twitter],
            contact2Instagram: [information.contact2Instagram],
            contact2Linkedin: [information.contact2Linkedin],
            contact2Tiktok: [information.contact2Tiktok],
            contact3FullName: [information.contact3FullName],
            contact3FirstName: [information.contact3FirstName],
            contact3LastName: [information.contact3LastName],
            contact3Relation: [information.contact3Relation],
            contact3FullAddress: [information.contact3FullAddress],
            contact3HouseNumber: [information.contact3HouseNumber],
            contact3StreetName: [information.contact3StreetName],
            contact3City: [information.contact3City],
            contact3District: [information.contact3District],
            contact3State: [information.contact3State],
            contact3PostalCode: [information.contact3PostalCode],
            contact3Country: [information.contact3Country],
            contact3ResidenceDetails: [information.contact3ResidenceDetails],
            contact3Phone: [information.contact3Phone],
            contact3Email: [information.contact3Email],
            contact3Facebook: [information.contact3Facebook],
            contact3Twitter: [information.contact3Twitter],
            contact3Instagram: [information.contact3Instagram],
            contact3Linkedin: [information.contact3Linkedin],
            contact3Tiktok: [information.contact3Tiktok],
            contact4FullName: [information.contact4FullName],
            contact4FirstName: [information.contact4FirstName],
            contact4LastName: [information.contact4LastName],
            contact4Relation: [information.contact4Relation],
            contact4FullAddress: [information.contact4FullAddress],
            contact4HouseNumber: [information.contact4HouseNumber],
            contact4StreetName: [information.contact4StreetName],
            contact4City: [information.contact4City],
            contact4District: [information.contact4District],
            contact4State: [information.contact4State],
            contact4PostalCode: [information.contact4PostalCode],
            contact4Country: [information.contact4Country],
            contact4ResidenceDetails: [information.contact4ResidenceDetails],
            contact4Phone: [information.contact4Phone],
            contact4Email: [information.contact4Email],
            contact4Facebook: [information.contact4Facebook],
            contact4Twitter: [information.contact4Twitter],
            contact4Instagram: [information.contact4Instagram],
            contact4Linkedin: [information.contact4Linkedin],
            contact4TikTok: [information.contact4TikTok],
            contact5FullName: [information.contact5FullName],
            contact5FirstName: [information.contact5FirstName],
            contact5LastName: [information.contact5LastName],
            contact5Relation: [information.contact5Relation],
            contact5HouseNumber: [information.contact5HouseNumber],
            contact5FullAddress: [information.contact5FullAddress],
            contact5StreetName: [information.contact5StreetName],
            contact5City: [information.contact5City],
            contact5District: [information.contact5District],
            contact5State: [information.contact5State],
            contact5PostalCode: [information.contact5PostalCode],
            contact5Country: [information.contact5Country],
            contact5ResidenceDetails: [information.contact5ResidenceDetails],
            contact5Phone: [information.contact5Phone],
            contact5Email: [information.contact5Email],
            contact5Facebook: [information.contact5Facebook],
            contact5Twitter: [information.contact5Twitter],
            contact5Instagram: [information.contact5Instagram],
            contact5Linkedin: [information.contact5Linkedin],
            contact5Tiktok: [information.contact5Tiktok],
            contact6FullName: [information.contact6FullName],
            nextOfKin6: [information.nextOfKin6],
            kin6Phone: [information.kin6Phone],
            kin6Email: [information.kin6Email],
            contact7FullName: [information.contact6FullName],
            nextOfKin7: [information.nextOfKin7],
            kin7Phone: [information.kin7Phone],
            kin7Email: [information.kin7Email],
            contact8FullName: [information.contact8FullName],
            nextOfKin8: [information.nextOfKin8],
            kin8Phone: [information.kin8Phone],
            kin8Email: [information.kin8Email],
          });

          this.setValidators();
        }
      });
    }
  }

  setValidators() {
    /*if (this.authService.getUserType() === 'interviewer') {
      this.familyInformationForm.controls['contact1FirstName'].setValidators(Validators.required);
      this.familyInformationForm.controls['contact1FirstName'].updateValueAndValidity();
      this.familyInformationForm.controls['contact1LastName'].setValidators(Validators.required);
      this.familyInformationForm.controls['contact1LastName'].updateValueAndValidity();
      this.familyInformationForm.controls['contact1FullName'].setValidators(Validators.required);
      this.familyInformationForm.controls['contact1FullName'].updateValueAndValidity();
      this.familyInformationForm.controls['contact1Relation'].setValidators(Validators.required);
      this.familyInformationForm.controls['contact1Relation'].updateValueAndValidity();
      this.familyInformationForm.controls['contact1Country'].setValidators(Validators.required);
      this.familyInformationForm.controls['contact1Country'].updateValueAndValidity();
      this.familyInformationForm.controls['contact1Phone'].setValidators(Validators.required);
      this.familyInformationForm.controls['contact1Phone'].updateValueAndValidity();
      this.familyInformationForm.controls['contact1Email'].setValidators(Validators.required);
      this.familyInformationForm.controls['contact1Email'].updateValueAndValidity();
      this.familyInformationForm.controls['contact2FirstName'].setValidators(Validators.required);
      this.familyInformationForm.controls['contact2FirstName'].updateValueAndValidity();
      this.familyInformationForm.controls['contact2LastName'].setValidators(Validators.required);
      this.familyInformationForm.controls['contact2LastName'].updateValueAndValidity();
      this.familyInformationForm.controls['contact2FullName'].setValidators(Validators.required);
      this.familyInformationForm.controls['contact2FullName'].updateValueAndValidity();
      this.familyInformationForm.controls['contact2Relation'].setValidators(Validators.required);
      this.familyInformationForm.controls['contact2Relation'].updateValueAndValidity();
      this.familyInformationForm.controls['contact2Country'].setValidators(Validators.required);
      this.familyInformationForm.controls['contact2Country'].updateValueAndValidity();
      this.familyInformationForm.controls['contact2Phone'].setValidators(Validators.required);
      this.familyInformationForm.controls['contact2Phone'].updateValueAndValidity();
      this.familyInformationForm.controls['contact2Email'].setValidators(Validators.required);
      this.familyInformationForm.controls['contact2Email'].updateValueAndValidity();
    }*/
  }

  saveForm() {
    if (!this.familyInformationForm.valid) {
      this.toastr.error('Please complete all required fields.');
      return;
    }
    const contact1FullName = this.familyInformationForm.controls['contact1FullName'].value;
    const contact1FirstName = this.familyInformationForm.controls['contact1FirstName'].value;
    const contact1LastName = this.familyInformationForm.controls['contact1LastName'].value;
    const contact1Relation = this.familyInformationForm.controls['contact1Relation'].value;
    const contact1Address = this.familyInformationForm.controls['contact1Address'].value;
    const contact1HouseNumber = this.familyInformationForm.controls['contact1HouseNumber'].value;
    const contact1StreetName = this.familyInformationForm.controls['contact1StreetName'].value;
    const contact1City = this.familyInformationForm.controls['contact1City'].value;
    const contact1District = this.familyInformationForm.controls['contact1District'].value;
    const contact1State = this.familyInformationForm.controls['contact1State'].value;
    const contact1PostalCode = this.familyInformationForm.controls['contact1PostalCode'].value;
    const contact1Country = this.familyInformationForm.controls['contact1Country'].value;
    const contact1ResidenceDetails = this.familyInformationForm.controls['contact1ResidenceDetails'].value;
    const contact1Phone = this.familyInformationForm.controls['contact1Phone'].value;
    const contact1Email = this.familyInformationForm.controls['contact1Email'].value;
    const contact1Facebook = this.familyInformationForm.controls['contact1Facebook'].value;
    const contact1Twitter = this.familyInformationForm.controls['contact1Twitter'].value;
    const contact1Instagram = this.familyInformationForm.controls['contact1Instagram'].value;
    const contact1Linkedin = this.familyInformationForm.controls['contact1Linkedin'].value;
    const contact1Tiktok = this.familyInformationForm.controls['contact1Tiktok'].value;
    const contact2FullName = this.familyInformationForm.controls['contact2FullName'].value;
    const contact2FirstName = this.familyInformationForm.controls['contact2FirstName'].value;
    const contact2LastName = this.familyInformationForm.controls['contact2LastName'].value;
    const contact2Relation = this.familyInformationForm.controls['contact2Relation'].value;
    const contact2FullAddress = this.familyInformationForm.controls['contact2FullAddress'].value;
    const contact2HouseNumber = this.familyInformationForm.controls['contact2HouseNumber'].value;
    const contact2StreetName = this.familyInformationForm.controls['contact2StreetName'].value;
    const contact2City = this.familyInformationForm.controls['contact2City'].value;
    const contact2District = this.familyInformationForm.controls['contact2District'].value;
    const contact2State = this.familyInformationForm.controls['contact2State'].value;
    const contact2PostalCode = this.familyInformationForm.controls['contact2PostalCode'].value;
    const contact2Country = this.familyInformationForm.controls['contact2Country'].value;
    const contact2ResidenceDetails = this.familyInformationForm.controls['contact2ResidenceDetails'].value;
    const contact2Phone = this.familyInformationForm.controls['contact2Phone'].value;
    const contact2Email = this.familyInformationForm.controls['contact2Email'].value;
    const contact2Facebook = this.familyInformationForm.controls['contact2Facebook'].value;
    const contact2Twitter = this.familyInformationForm.controls['contact2Twitter'].value;
    const contact2Instagram = this.familyInformationForm.controls['contact2Instagram'].value;
    const contact2Linkedin = this.familyInformationForm.controls['contact2Linkedin'].value;
    const contact2Tiktok = this.familyInformationForm.controls['contact2Tiktok'].value;
    const contact3FullName = this.familyInformationForm.controls['contact3FullName'].value;
    const contact3FirstName = this.familyInformationForm.controls['contact3FirstName'].value;
    const contact3LastName = this.familyInformationForm.controls['contact3LastName'].value;
    const contact3Relation = this.familyInformationForm.controls['contact3Relation'].value;
    const contact3FullAddress = this.familyInformationForm.controls['contact3FullAddress'].value;
    const contact3HouseNumber = this.familyInformationForm.controls['contact3HouseNumber'].value;
    const contact3StreetName = this.familyInformationForm.controls['contact3StreetName'].value;
    const contact3City = this.familyInformationForm.controls['contact3City'].value;
    const contact3District = this.familyInformationForm.controls['contact3District'].value;
    const contact3State = this.familyInformationForm.controls['contact3State'].value;
    const contact3PostalCode = this.familyInformationForm.controls['contact3PostalCode'].value;
    const contact3Country = this.familyInformationForm.controls['contact3Country'].value;
    const contact3ResidenceDetails = this.familyInformationForm.controls['contact3ResidenceDetails'].value;
    const contact3Phone = this.familyInformationForm.controls['contact3Phone'].value;
    const contact3Email = this.familyInformationForm.controls['contact3Email'].value;
    const contact3Facebook = this.familyInformationForm.controls['contact3Facebook'].value;
    const contact3Twitter = this.familyInformationForm.controls['contact3Twitter'].value;
    const contact3Instagram = this.familyInformationForm.controls['contact3Instagram'].value;
    const contact3Linkedin = this.familyInformationForm.controls['contact3Linkedin'].value;
    const contact3Tiktok = this.familyInformationForm.controls['contact3Tiktok'].value;
    const contact4FullName = this.familyInformationForm.controls['contact4FullName'].value;
    const contact4FirstName = this.familyInformationForm.controls['contact4FirstName'].value;
    const contact4LastName = this.familyInformationForm.controls['contact4LastName'].value;
    const contact4Relation = this.familyInformationForm.controls['contact4Relation'].value;
    const contact4FullAddress = this.familyInformationForm.controls['contact4FullAddress'].value;
    const contact4HouseNumber = this.familyInformationForm.controls['contact4HouseNumber'].value;
    const contact4StreetName = this.familyInformationForm.controls['contact4StreetName'].value;
    const contact4City = this.familyInformationForm.controls['contact4City'].value;
    const contact4District = this.familyInformationForm.controls['contact4District'].value;
    const contact4State = this.familyInformationForm.controls['contact4State'].value;
    const contact4PostalCode = this.familyInformationForm.controls['contact4PostalCode'].value;
    const contact4Country = this.familyInformationForm.controls['contact4Country'].value;
    const contact4ResidenceDetails = this.familyInformationForm.controls['contact4ResidenceDetails'].value;
    const contact4Phone = this.familyInformationForm.controls['contact4Phone'].value;
    const contact4Email = this.familyInformationForm.controls['contact4Email'].value;
    const contact4Facebook = this.familyInformationForm.controls['contact4Facebook'].value;
    const contact4Twitter = this.familyInformationForm.controls['contact4Twitter'].value;
    const contact4Instagram = this.familyInformationForm.controls['contact4Instagram'].value;
    const contact4Linkedin = this.familyInformationForm.controls['contact4Linkedin'].value;
    const contact4TikTok = this.familyInformationForm.controls['contact4TikTok'].value;
    const contact5FullName = this.familyInformationForm.controls['contact5FullName'].value;
    const contact5FirstName = this.familyInformationForm.controls['contact5FirstName'].value;
    const contact5LastName = this.familyInformationForm.controls['contact5LastName'].value;
    const contact5Relation = this.familyInformationForm.controls['contact5Relation'].value;
    const contact5FullAddress = this.familyInformationForm.controls['contact5FullAddress'].value;
    const contact5HouseNumber = this.familyInformationForm.controls['contact5HouseNumber'].value;
    const contact5StreetName = this.familyInformationForm.controls['contact5StreetName'].value;
    const contact5City = this.familyInformationForm.controls['contact5City'].value;
    const contact5District = this.familyInformationForm.controls['contact5District'].value;
    const contact5State = this.familyInformationForm.controls['contact5State'].value;
    const contact5PostalCode = this.familyInformationForm.controls['contact5PostalCode'].value;
    const contact5Country = this.familyInformationForm.controls['contact5Country'].value;
    const contact5ResidenceDetails = this.familyInformationForm.controls['contact5ResidenceDetails'].value;
    const contact5Phone = this.familyInformationForm.controls['contact5Phone'].value;
    const contact5Email = this.familyInformationForm.controls['contact5Email'].value;
    const contact5Facebook = this.familyInformationForm.controls['contact5Facebook'].value;
    const contact5Twitter = this.familyInformationForm.controls['contact5Twitter'].value;
    const contact5Instagram = this.familyInformationForm.controls['contact5Instagram'].value;
    const contact5Linkedin = this.familyInformationForm.controls['contact5Linkedin'].value;
    const contact5Tiktok = this.familyInformationForm.controls['contact5Tiktok'].value;
    const contact6FullName = this.familyInformationForm.controls['contact6FullName'].value;
    const nextOfKin6 = this.familyInformationForm.controls['nextOfKin6'].value;
    const kin6Phone = this.familyInformationForm.controls['kin6Phone'].value;
    const kin6Email = this.familyInformationForm.controls['kin6Email'].value;
    const contact7FullName = this.familyInformationForm.controls['contact7FullName'].value;
    const nextOfKin7 = this.familyInformationForm.controls['nextOfKin7'].value;
    const kin7Phone = this.familyInformationForm.controls['kin7Phone'].value;
    const kin7Email = this.familyInformationForm.controls['kin7Email'].value;
    const contact8FullName = this.familyInformationForm.controls['contact8FullName'].value;
    const nextOfKin8 = this.familyInformationForm.controls['nextOfKin8'].value;
    const kin8Phone = this.familyInformationForm.controls['kin8Phone'].value;
    const kin8Email = this.familyInformationForm.controls['kin8Email'].value;
    const interviewID = this.interviewID;
    let data = { interviewID, contact1FullName, contact1FirstName, contact1LastName, contact1Relation, contact1Address, contact1HouseNumber, contact1StreetName, contact1City, contact1District, 
      contact1State, contact1PostalCode, contact1Country, contact1ResidenceDetails, contact1Phone, contact1Email, contact1Facebook, contact1Twitter, contact1Instagram,
      contact1Linkedin, contact1Tiktok, contact2FullName, contact2FirstName, contact2LastName, contact2Relation, contact2FullAddress, contact2HouseNumber, contact2StreetName, contact2City, contact2District, 
      contact2State, contact2PostalCode, contact2Country, contact2ResidenceDetails, contact2Phone, contact2Email, contact2Facebook, contact2Twitter, contact2Instagram,
      contact2Linkedin, contact2Tiktok, contact3FullName, contact3FirstName, contact3LastName, contact3Relation, contact3FullAddress, contact3HouseNumber, contact3StreetName, contact3City, contact3District, 
      contact3State, contact3PostalCode, contact3Country, contact3ResidenceDetails, contact3Phone, contact3Email, contact3Facebook, contact3Twitter, contact3Instagram,
      contact3Linkedin, contact3Tiktok, contact4FullName, contact4FirstName, contact4LastName, contact4Relation, contact4FullAddress, contact4HouseNumber, contact4StreetName, contact4City, contact4District, 
      contact4State, contact4PostalCode, contact4Country, contact4ResidenceDetails, contact4Phone, contact4Email, contact4Facebook, contact4Twitter, contact4Instagram,
      contact4Linkedin, contact4TikTok, contact5FullName, contact5FirstName, contact5LastName, contact5Relation, contact5FullAddress, contact5HouseNumber, contact5StreetName, contact5City, contact5District, 
      contact5State, contact5PostalCode, contact5Country, contact5ResidenceDetails, contact5Phone, contact5Email, contact5Facebook, contact5Twitter, contact5Instagram,
      contact5Linkedin, contact5Tiktok, contact6FullName, nextOfKin6, kin6Phone, kin6Email, contact7FullName, nextOfKin7, kin7Phone, kin7Email, contact8FullName, nextOfKin8, kin8Phone, kin8Email };
    this.backendService.saveFamilyInformation(data)
      .subscribe(() => this.toastr.success('Friends and Family Information', 'Data saved successfully'))
    this.formSaved.emit();
  }
}
