<div class="form-title mt-3 mb-3">
  Interviewee Communications
</div>
<div class="form-data" [formGroup]="intervieweeCommunicationsForm">
  <div class="form-group">
    <label for="postInterviewPackage" class="required">Post interview package</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="postInterviewPackage" value="Not Yet Prepared" id="notYetPreparedPostInterviewPackage" formControlName="postInterviewPackage">
      <label class="form-check-label" for="notYetPreparedPostInterviewPackage">
        Not Yet Prepared
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="postInterviewPackage" value="Prepared" id="preparedPostInterviewPackage" formControlName="postInterviewPackage">
      <label class="form-check-label" for="preparedPostInterviewPackage">
        Prepared
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="postInterviewPackage" value="Sent" id="sentPostInterviewPackage" formControlName="postInterviewPackage">
      <label class="form-check-label" for="sentPostInterviewPackage">
        Sent
      </label>
    </div>
  </div>
  <div class="form-group">
    <label for="dvd" class="required">DVD ordered & sent?</label>
    <input type="text" class="form-input" id="dvd" formControlName="dvd">
  </div>
  <div class="form-group">
    <label for="postInterviewCall" class="required">Post Interview Phone Call</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="postInterviewCall" value="Yes" id="yesPostInterviewCall" formControlName="postInterviewCall">
      <label class="form-check-label" for="yesPostInterviewCall">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="postInterviewCall" value="No" id="noPostInterviewCall" formControlName="postInterviewCall">
      <label class="form-check-label" for="noPostInterviewCall">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label for="postInterviewCallNotes">Notes for the Post-Interview Phone Call</label>
    <textarea
      id="postInterviewCallNotes"
      formControlName="postInterviewCallNotes"
      class="w-100 form-textarea"
      (paste)="onPaste($event, 'postInterviewCallNotes')"
      >
    </textarea>
  </div>
  <div class="form-group">
    <label for="deceased">Date of Interviewee's Passing Away</label>
    <input type="text" class="form-input" id="deceased" formControlName="deceased">
  </div>
</div>
<div class="mt-5 text-center">
    <button class="save-button" (click)="saveForm()">Save</button>
</div>
