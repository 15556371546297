import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-narrator-information',
  templateUrl: './narrator-information.component.html',
  styleUrls: ['./narrator-information.component.scss']
})
export class NarratorInformationComponent implements OnInit, OnChanges {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: string;
  public narratorForm: FormGroup;

  constructor(public fb: FormBuilder,
              private backendService: BackendService,
              private toastr: ToastrService) { }

  ngOnInit() {
    this.narratorForm = this.fb.group({
      relationship: [ null ],
      additionalNarrators: [ null ],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getNarratorInformation(this.interviewID).subscribe(narrator => {
        if (narrator) {
          this.narratorForm = this.fb.group({
            relationship: [ narrator.relationship ],
            additionalNarrators: [ narrator.additionalNarrators ]
          });
        }
      });
    }
  }

  saveForm() {
    const interviewID = this.interviewID;
    const relationship = this.narratorForm.controls['relationship'].value;
    const additionalNarrators = this.narratorForm.controls['additionalNarrators'].value;
    let data = {
      interviewID,
      relationship,
      additionalNarrators
    };
    this.backendService.saveNarrator(data)
      .subscribe(() => {
        this.toastr.success('Narrator Information', 'Data saved successfully');
        this.formSaved.emit();
      })
  }

  onPaste(event: ClipboardEvent, formControlName: string) {
  }
}
