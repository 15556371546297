<div class="form-title mt-3 mb-3">
    Narrator Contact Information
</div>
<div class="form-data" [formGroup]="narratorContactInformationForm">
  <div class="form-group">
    <label for="additionalInfo">Additional Narrators contact info</label>
    <textarea id="additionalInfo" class="w-100 form-textarea" formControlName="additionalInfo" (paste)="onPaste($event, 'additionalInfo')"></textarea>
  </div>
</div>
<div class="mt-5 text-center">
    <button class="save-button" (click)="saveForm()">Save</button>
</div>
