import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-interview-review-content',
  templateUrl: './interview-review-content.component.html',
  styleUrls: ['./interview-review-content.component.scss']
})
export class InterviewReviewContentComponent implements OnInit {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: number;
  public contentForm: FormGroup;

  constructor(private fb: FormBuilder,
              private backendService: BackendService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.contentForm = this.fb.group({
      coverAllSuggestedQuestions: [null ],
      richDetailResponse: [null ],
      formQuestions: [null ],
      encourageToElaborate: [null ],
      unbiased: [null ],
      focusOnRemembrances: [null ],
      succeedInBettering: [null ],
      scoreContent: [null ],
      contentNotes: [null ],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getInterviewReviewContent(this.interviewID).subscribe(information => {
        if (information) {
          this.contentForm = this.fb.group({
            coverAllSuggestedQuestions: [information.coverAllSuggestedQuestions ],
            richDetailResponse: [information.richDetailResponse ],
            formQuestions: [information.formQuestions ],
            encourageToElaborate: [information.encourageToElaborate ],
            unbiased: [information.unbiased ],
            focusOnRemembrances: [information.focusOnRemembrances ],
            succeedInBettering: [information.succeedInBettering ],
            scoreContent: [information.scoreContent ],
            contentNotes: [information.contentNotes ],
          });
        }
      });
    }
  }

  saveForm() {
    const coverAllSuggestedQuestions = this.contentForm.controls['coverAllSuggestedQuestions'].value;
    const richDetailResponse = this.contentForm.controls['richDetailResponse'].value;
    const formQuestions = this.contentForm.controls['formQuestions'].value;
    const encourageToElaborate = this.contentForm.controls['encourageToElaborate'].value;
    const unbiased = this.contentForm.controls['unbiased'].value;
    const focusOnRemembrances = this.contentForm.controls['focusOnRemembrances'].value;
    const succeedInBettering = this.contentForm.controls['succeedInBettering'].value;
    const scoreContent = this.contentForm.controls['scoreContent'].value;
    const contentNotes = this.contentForm.controls['contentNotes'].value;
    const interviewID = this.interviewID;
    let data = {
      interviewID, coverAllSuggestedQuestions, richDetailResponse, formQuestions,
      encourageToElaborate, unbiased, focusOnRemembrances, succeedInBettering, scoreContent,
      contentNotes
    };
    this.backendService.saveInterviewReviewContent(data)
      .subscribe(() => this.toastr.success('Content', 'Data saved successfully'))
    this.formSaved.emit();
  }

  onPaste(event: ClipboardEvent, formControlName: string) {
  }
}
