<div class="form-title mt-3 mb-3">
    Interviewee Preferences
</div>
<div class="form-data" [formGroup]="intervieweePreferencesForm">
  <div class="form-group mt-4">
    <label class="required">Project website: I, the interviewee, am comfortable if my testimony is made available publicly on The 1947PA website.</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="projectWebsite" value="Yes, video and transcript" id="videoAndTranscript" formControlName="projectWebsite">
      <label class="form-check-label" for="videoAndTranscript">
        Yes, video and transcript
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="projectWebsite" value="Yes transcript or summary only" id="transcriptOnly" formControlName="projectWebsite">
      <label class="form-check-label" for="transcriptOnly">
        Yes transcript or summary only
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="projectWebsite" value="Yes, video only" id="videoOnly" formControlName="projectWebsite">
      <label class="form-check-label" for="videoOnly">
        Yes, video only
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="projectWebsite" value="Yes, but i choose to go by a pseudonym" id="pseudonym" formControlName="projectWebsite">
      <label class="form-check-label" for="pseudonym">
        Yes, but i choose to go by a pseudonym
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="projectWebsite" value="No" id="noPreference" formControlName="projectWebsite">
      <label class="form-check-label" for="noPreference">
        No
      </label>
    </div>
  </div>
  <div class="form-group mt-4">
    <label class="required">YouTube, Vimeo or other online video channel:  I, the interviewee, am comfortable if my testimony is made available in whole or in part on The 1947PA’s online video channels.</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="onlineVideoChannel" value="Yes" id="yesOnlineVideo" formControlName="onlineVideoChannel">
      <label class="form-check-label" for="yesOnlineVideo">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="onlineVideoChannel" value="Yes, but i choose to remain anonymous" id="yesAnonymous" formControlName="onlineVideoChannel">
      <label class="form-check-label" for="yesAnonymous">
        Yes, but i choose to remain anonymous
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="onlineVideoChannel" value="Yes, but i choose to go by a pseudonym" id="yesPseudonym" formControlName="onlineVideoChannel">
      <label class="form-check-label" for="yesPseudonym">
        Yes, but i choose to go by a pseudonym
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="onlineVideoChannel" value="No" id="noVideoChannel" formControlName="onlineVideoChannel">
      <label class="form-check-label" for="noVideoChannel">
        No
      </label>
    </div>
  </div>
  <div class="form-group mt-4">
    <label class="required">Facebook, Twitter, and other Social Media:  I, the interviewee, am comfortable if my testimony is made available in whole or in part on The 1947 Partition Archive’s Facebook and Twitter pages.</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="socialMedia" value="Yes" id="yesSocialMedia" formControlName="socialMedia">
      <label class="form-check-label" for="yesSocialMedia">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="socialMedia" value="Yes, but i choose to remain anonymous" id="yesAnonymousSocialMedia" formControlName="socialMedia">
      <label class="form-check-label" for="yesAnonymousSocialMedia">
        Yes, but i choose to remain anonymous
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="socialMedia" value="Yes, but i choose to go by a pseudonym" id="yesPseudonymSocialMedia" formControlName="socialMedia">
      <label class="form-check-label" for="yesPseudonymSocialMedia">
        Yes, but i choose to go by a pseudonym
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="socialMedia" value="No" id="noSocialMedia" formControlName="socialMedia">
      <label class="form-check-label" for="noSocialMedia">
        No
      </label>
    </div>
  </div>
  <div class="form-group mt-4">
    <label class="required">Radio broadcast: I, the interviewee, am comfortable if my testimony is made available in whole or part for occasional radio broadcast.</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="radioBroadcast" value="Yes" id="yesRadioBroadcast" formControlName="radioBroadcast">
      <label class="form-check-label" for="yesRadioBroadcast">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="radioBroadcast" value="Yes, but i choose to remain anonymous" id="yesAnonymousRadioBroadcast" formControlName="radioBroadcast">
      <label class="form-check-label" for="yesAnonymousRadioBroadcast">
        Yes, but i choose to remain anonymous
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="radioBroadcast" value="Yes, but i choose to go by a pseudonym" id="yesPseudonymRadioBroadcast" formControlName="radioBroadcast">
      <label class="form-check-label" for="yesPseudonymRadioBroadcast">
        Yes, but i choose to go by a pseudonym
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="radioBroadcast" value="No" id="noRadioBroadcast" formControlName="radioBroadcast">
      <label class="form-check-label" for="noRadioBroadcast">
        No
      </label>
    </div>
  </div>
  <div class="form-group mt-4">
    <label class="required">Television broadcast: I, the interviewee, am comfortable if my testimony is made available in whole or part for occasional television broadcast.</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="tvBroadcast" value="Yes" id="yesTvBroadcast" formControlName="tvBroadcast">
      <label class="form-check-label" for="yesTvBroadcast">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="tvBroadcast" value="Yes, but i choose to remain anonymous" id="yesAnonymousTvBroadcast" formControlName="tvBroadcast">
      <label class="form-check-label" for="yesAnonymousTvBroadcast">
        Yes, but i choose to remain anonymous
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="tvBroadcast" value="Yes, but i choose to go by a pseudonym" id="yesPseudonymTvBroadcast" formControlName="tvBroadcast">
      <label class="form-check-label" for="yesPseudonymTvBroadcast">
        Yes, but i choose to go by a pseudonym
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="tvBroadcast" value="No" id="noTvBroadcast" formControlName="tvBroadcast">
      <label class="form-check-label" for="noTvBroadcast">
        No
      </label>
    </div>
  </div>
  <div class="form-group mt-4">
    <label class="required">Print: I, the interviewee, am comfortable if my testimony is made available in whole or part in printed form.</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="printAgreement" value="Yes" id="yesPrintAgreement" formControlName="printAgreement">
      <label class="form-check-label" for="yesPrintAgreement">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="printAgreement" value="Yes, but i choose to remain anonymous" id="yesAnonymousPrintAgreement" formControlName="printAgreement">
      <label class="form-check-label" for="yesAnonymousPrintAgreement">
        Yes, but i choose to remain anonymous
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="printAgreement" value="Yes, but i choose to go by a pseudonym" id="yesPseudonymPrintAgreement" formControlName="printAgreement">
      <label class="form-check-label" for="yesPseudonymPrintAgreement">
        Yes, but i choose to go by a pseudonym
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="printAgreement" value="No" id="noPrintAgreement" formControlName="printAgreement">
      <label class="form-check-label" for="noPrintAgreement">
        No
      </label>
    </div>
  </div>
  <div class="form-group mt-4">
    <label class="required"> Delay: If you, the interviewee, are providing information that you consider sensitive, you may elect to delay the release of this information (i.e., 10 years, 50 years, or more). Until release, this document will remain sealed.</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="delayOption" value="No, make available immeadiately" id="noDelay" formControlName="delayOption">
      <label class="form-check-label" for="noDelay">
        No, make available immeadiately
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="delayOption" value="Yes, delay" id="yesDelay" formControlName="delayOption">
      <label class="form-check-label" for="yesDelay">
        Yes, delay
      </label>
    </div>
    <div class="mt-3 d-flex align middle" *ngIf="intervieweePreferencesForm.controls['delayOption'].value === 'Yes, delay'">
      <label for="delayDate" class="required mr-3">Delay until</label>
      <div class="input-group w-50">
        <input type="text" class="form-input" id="delayDate" formControlName="delayDate" required>
      </div>
    </div>
  </div>
  <div class="form-group mt-4">
    <label class="required">Would you, the interviewee, be interested in participating in public speaking events that involve narrating your Partition experiences?</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="speakingEvents" value="Yes" id="yesSpeakingEvents" formControlName="speakingEvents">
      <label class="form-check-label" for="yesSpeakingEvents">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="speakingEvents" value="No" id="noSpeakingEvents" formControlName="speakingEvents">
      <label class="form-check-label" for="noSpeakingEvents">
        No
      </label>
    </div>
  </div>
  <div class="form-group mt-4">
    <label class="required">May we contact you, the interviewee, in the future for more details with regard to your story?</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="moreDetails" value="Yes" id="yesMoreDetails" formControlName="moreDetails">
      <label class="form-check-label" for="yesMoreDetails">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="moreDetails" value="No" id="noMoreDetails" formControlName="moreDetails">
      <label class="form-check-label" for="noMoreDetails">
        No
      </label>
    </div>
  </div>
  <div class="form-group mt-4" *ngIf="userType !== 'interviewer'">
    <label for="permissions">Permissions</label>
    <textarea id="permissions" class="w-100 form-textarea" formControlName="permissions" (paste)="onPaste($event, 'permissions')"></textarea>
  </div>
  <div class="form-group mt-4">
    <label for="notesPermissions">Notes</label>
    <textarea id="notesPermissions" class="w-100 form-textarea" formControlName="notesPermissions" (paste)="onPaste($event, 'notesPermissions')"></textarea>
  </div>
</div>
<div class="mt-5 text-center">
    <button class="save-button" (click)="saveForm()">Save</button>
</div>
