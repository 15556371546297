<div class="form-title mt-3 mb-3">
  Filming
</div>
<div class="form-data" [formGroup]="filmingForm">
  <div class="form-group">
    <label class="required">Is the camera view well framed? (Is the background attractive but not distracting and is the subject well placed?)</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="wellFramed" value="Yes" id="yesWellFramed" formControlName="wellFramed">
      <label class="form-check-label" for="yesWellFramed">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="wellFramed" value="No" id="noWellFramed" formControlName="wellFramed">
      <label class="form-check-label" for="noWellFramed">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="required">Is the light satisfactory?</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="lightSatisfactory" value="Yes" id="yesLightSatisfactory" formControlName="lightSatisfactory">
      <label class="form-check-label" for="yesLightSatisfactory">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="lightSatisfactory" value="No" id="noLightSatisfactory" formControlName="lightSatisfactory">
      <label class="form-check-label" for="noLightSatisfactory">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="required">Are the voices of both the interviewee and interviewer clear and recorded at an adequate volume?</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="voiceClear" value="Yes" id="yesVoiceClear" formControlName="voiceClear">
      <label class="form-check-label" for="yesVoiceClear">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="voiceClear" value="No" id="noVoiceClear" formControlName="voiceClear">
      <label class="form-check-label" for="noVoiceClear">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="required">Is the subject looking at the same relative point of focus (just to the side of the camera) throughout the entirety of the video?</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="pointOfFocus" value="Yes" id="yesPointOfFocus" formControlName="pointOfFocus">
      <label class="form-check-label" for="yesPointOfFocus">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="pointOfFocus" value="No" id="noPointOfFocus" formControlName="pointOfFocus">
      <label class="form-check-label" for="noPointOfFocus">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label class="required">Is the interview filmed in a private and quiet location without much background noice?</label>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="filmingInterviewLocation" value="Yes" id="yesFilmingInterviewLocation" formControlName="filmingInterviewLocation">
      <label class="form-check-label" for="yesFilmingInterviewLocation">
        Yes
      </label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="filmingInterviewLocation" value="No" id="noFilmingInterviewLocation" formControlName="filmingInterviewLocation">
      <label class="form-check-label" for="noFilmingInterviewLocation">
        No
      </label>
    </div>
  </div>
  <div class="form-group">
    <label for="scoreFilming" class="required">Overall Filming Score (1 being poor and 10 being excellent)</label>
    <input type="number" min="1" max="10" class="form-input" id="scoreFilming" formControlName="scoreFilming">
  </div>
  <div class="form-group">
    <label for="notesFilming" class="required">Notes and Comments</label>
    <textarea id="notesFilming" class="w-100 form-textarea" formControlName="notesFilming" (paste)="onPaste($event, 'notesFilming')"></textarea>
  </div>
</div>
<div class="mt-5 text-center">
    <button class="save-button" (click)="saveForm()">Save</button>
</div>
