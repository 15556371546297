import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-migration-details',
  templateUrl: './migration-details.component.html',
  styleUrls: ['./migration-details.component.scss']
})
export class MigrationDetailsComponent implements OnInit {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: string;
  public migrationDetailsForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private backendService: BackendService,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.migrationDetailsForm = this.fb.group({
      assetsLost: [null ],
      assetsAlloted: [null ],
      pointOfEntry: [null ],
      routeTraveled: [null ],
      additionMaterials: [null ]
    });

    this.setValidators();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getMigrationDetails(this.interviewID).subscribe(details => {
        if (details) {
          this.migrationDetailsForm = this.fb.group({
            assetsLost: [details.assetsLost ],
            assetsAlloted: [details.assetsAlloted ],
            pointOfEntry: [details.pointOfEntry ],
            routeTraveled: [details.routeTraveled ],
            additionMaterials: [details.additionMaterials ]
          });

          this.setValidators();
        }
      });
    }
  }

  setValidators() {
    /*if (this.authService.getUserType() === 'interviewer') {
      this.migrationDetailsForm.controls['assetsLost'].setValidators(Validators.required);
      this.migrationDetailsForm.controls['assetsLost'].updateValueAndValidity();
      this.migrationDetailsForm.controls['assetsAlloted'].setValidators(Validators.required);
      this.migrationDetailsForm.controls['assetsAlloted'].updateValueAndValidity();
      this.migrationDetailsForm.controls['pointOfEntry'].setValidators(Validators.required);
      this.migrationDetailsForm.controls['pointOfEntry'].updateValueAndValidity();
      this.migrationDetailsForm.controls['routeTraveled'].setValidators(Validators.required);
      this.migrationDetailsForm.controls['routeTraveled'].updateValueAndValidity();
      this.migrationDetailsForm.controls['additionMaterials'].setValidators(Validators.required);
      this.migrationDetailsForm.controls['additionMaterials'].updateValueAndValidity();
    }*/
  }

  saveForm() {
    if (!this.migrationDetailsForm.valid) {
      this.toastr.error('Please complete all required fields.');
      return;
    }
    const assetsLost = this.migrationDetailsForm.controls['assetsLost'].value;
    const assetsAlloted = this.migrationDetailsForm.controls['assetsAlloted'].value;
    const pointOfEntry = this.migrationDetailsForm.controls['pointOfEntry'].value;
    const routeTraveled = this.migrationDetailsForm.controls['routeTraveled'].value;
    const additionMaterials = this.migrationDetailsForm.controls['additionMaterials'].value;
    const interviewID = this.interviewID;
    let data = { interviewID, assetsLost, assetsAlloted, pointOfEntry, routeTraveled, additionMaterials };
    this.backendService.saveMigrationDetails(data)
      .subscribe(() => this.toastr.success('Migration Details', 'Data saved successfully'))
    this.formSaved.emit();
  }

}
