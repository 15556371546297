import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-copy-editing-and-curation',
  templateUrl: './copy-editing-and-curation.component.html',
  styleUrls: ['./copy-editing-and-curation.component.scss']
})
export class CopyEditingAndCurationComponent implements OnInit, OnChanges {

  @Output() formSaved = new EventEmitter<any>();
  @Input() interviewID: number;
  public copyEditingAndCurationForm: FormGroup;
  public rawUneditedSummary = '';

  constructor(
    private fb: FormBuilder,
    private backendService: BackendService,
    private toastr: ToastrService,
    private parserFormatter: NgbDateParserFormatter,
  ) { }

  ngOnInit(): void {
    this.copyEditingAndCurationForm = this.fb.group({
      copyEditedSummaryDate: [],
      copyEditedSummary: [],
      factCheckEmailSentDate: [],
      factCheckCompletedDate: [],
      curatedSummary: [],
      notes: [],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.interviewID) {
      this.backendService.getCopyEditingAndCuration(this.interviewID).subscribe(data => {
        if (data) {
          this.copyEditingAndCurationForm = this.fb.group({
            copyEditedSummaryDate: [this.parserFormatter.parse(data.copyEditedSummaryDate)],
            copyEditedSummary: [data.copyEditedSummary],
            factCheckEmailSentDate: [this.parserFormatter.parse(data.factCheckEmailSentDate)],
            factCheckCompletedDate: [this.parserFormatter.parse(data.factCheckCompletedDate)],
            curatedSummary: [data.curatedSummary],
            notes: [data.notes],
          });
        }
      });

      this.backendService.getArchivistAbstractCuration(this.interviewID).subscribe(information => {
        if (information) {
          this.rawUneditedSummary = information.rawSummary;
        }
      });
    }
  }

  saveForm() {
    if(!this.copyEditingAndCurationForm.valid) {
      return;
    }
    const interviewID = this.interviewID;
    const copyEditedSummaryDate = this.parserFormatter.format(this.copyEditingAndCurationForm.controls['copyEditedSummaryDate'].value);
    const copyEditedSummary = this.copyEditingAndCurationForm.controls['copyEditedSummary'].value
    const factCheckEmailSentDate = this.parserFormatter.format(this.copyEditingAndCurationForm.controls['factCheckEmailSentDate'].value);
    const factCheckCompletedDate = this.parserFormatter.format(this.copyEditingAndCurationForm.controls['factCheckCompletedDate'].value);
    const curatedSummary = this.copyEditingAndCurationForm.controls['curatedSummary'].value
    const notes = this.copyEditingAndCurationForm.controls['notes'].value
    let data = {
      interviewID,
      copyEditedSummaryDate,
      copyEditedSummary,
      factCheckEmailSentDate,
      factCheckCompletedDate,
      curatedSummary,
      notes
    };

    this.backendService.saveCopyEditingAndCuration(data)
      .subscribe(() =>
        this.toastr.success('Copy-Editing and Curation', 'Data saved successfully')
      )

    this.formSaved.emit();
  }

  onPaste(event: ClipboardEvent, formControlName: string) {
  }
}
